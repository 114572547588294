import React, { PureComponent } from "react";
import { Route } from "react-router-dom";
import Unity, { UnityContent, UnityContext } from "react-unity-webgl";

import {connectEEGBluetooth, disconnectEEGBluetooth} from '../components/eeg-reader-and-scoring'
import {sendToUnity} from '../scripts/sendToUnity';


class MentalRunnerGame extends PureComponent {

  constructor(props) {
    super(props);
 
    this.unityContent = new UnityContent(
      "/mental-runner/Build/mental-runner.json",
      "/mental-runner/Build/UnityLoader.js"
    );

    this.state = {
      calmAve: 0,
      focusAve: 0,
      anxietyAve: 0
    };
  }

 
  render() {

    if (this.state.calmAve){
      sendToUnity(
        {
          unityInstance: this.unityContent, 
          gameObject: "Player-mouse",
          sentToMethod: "UpdateCalm", 
          itemToSend: parseFloat(this.state.calmAve)
        }
      )
    };
    if (this.state.focusAve){
      sendToUnity(
        {
          unityInstance: this.unityContent, 
          gameObject: "Player-mouse",
          sentToMethod: "UpdateFocus", 
          itemToSend: parseFloat(this.state.focusAve)
        }
      )
    }


    // this.sendToUnity("updateFocus", this.state.focusAve);
    console.log("this.state.eegBtConnection is ", this.state.eegBtConnection);

    return( 
      <>
        <button onClick={() => connectEEGBluetooth(this)}>Connect EEG</button>
        {this.state.eegBtConnection &&
        <Unity unityContent={this.unityContent} />
        }
      </>
      )
  }
}

export default MentalRunnerGame;