import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';




import { LineChart } from 'react-easy-chart';
import { CSVLink, CSVDownload } from 'react-csv';

import { filter, partition } from 'rxjs/operators';
import { MuseClient, channelNames } from 'muse-js';

import {
  epoch,
  fft,
  bufferFFT,
  pipe,
  createEEG,
  powerByBand, // Returns an array of power for each frequeny band
  averagePower, // Returns average power of each channel
  standardDeviation,
  pickChannels,
  removeChannels
} from '@neurosity/pipes';

// ---



class BrainGameText extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hrBtConnection: null,
      eegBtConnection: null,
      hr: null,
      isStarted: false,
      done: false,
      // Initalize and create array of arrays for alpha, beta, delta, gamma, and theta of each electrode
      flatData: [[]],
      dataCount: [],
      numElectrodes: 4,
      userText: 'tag',
      calmScores: [],
      calmAve: null,
      focusScores: [],
      focusAve: null,
      anxietyScores: [],
      anxietyAve: 0
    };
  }


  connectHRBluetooth = () => {
    let serverInstance;

    const theHrDevice = navigator.bluetooth.requestDevice({
      filters: [{ services: ['heart_rate'] }], //filters: [{namePrefix: 'Polar OH1'}],
      optionalServices: [
        'heart_rate',
        'generic_attribute',
        'battery_service',
        'blood_pressure',
        'tx_power',
        'generic_access',
        'fb005c80-02e7-f387-1cad-8acd2d8df0c8'
      ]
    }); //Working. name: Polar OH1 3F33B823

    const connectedHrDeviceServer = theHrDevice.then(device => 
      device.gatt.connect()
    );

    theHrDevice.then(device => {this.setState({connectedHrDevice: device})});


    // FIRST: get heart rate readings going
    connectedHrDeviceServer
      .then(server => {
        // Getting HR Service...
        return server.getPrimaryService('heart_rate'); // UUID for 'heart rate' // uuid 0000180d-0000-1000-8000-00805f9b34fb
      })
      .then(service => {
        // Getting HR Level Characteristic...
        return service.getCharacteristic('heart_rate_measurement'); // UUID for Heart Rate Reading (00002a37-0000-1000-8000-00805f9b34fb), polar oh1 device
      })
      .then(characteristic => characteristic.startNotifications())
      .then(characteristic => {
        characteristic.addEventListener(
          'characteristicvaluechanged',
          this.handleHrCharacteristicValueChanged
        );
        console.log('Notifications have been started for HR.');
        this.setState({ hrBtConnection: true });
      })
      .catch(error => {
        console.log('error is', error);
        console.log('error name is', error.name);
        console.log('error message is', error.message);
        console.log('error code is', error.code);
      });
  }; // End connect HR bluetooth


  disconnectHrBluetooth = () => {
    this.state.connectedHrDevice.gatt.disconnect()
    this.setState({ hrBtConnection: false });
  };


  handleHrCharacteristicValueChanged = event => {
    var value = event.target.value;
    var readableHrInfo = value.getInt8(1);
    this.setState({ hr: readableHrInfo });

    return readableHrInfo;
    // TODO: Parse Heart Rate Measurement value.
    // See https://github.com/WebBluetoothCG/demos/blob/gh-pages/heart-rate-sensor/heartRateSensor.js
  };


  // Function to connect the Muse EEG reader; split EEGstreams, by electrode, into 4 observable streams, and call logStreamData for each stream
  connectEEGBluetooth = async () => {
    const Client = new MuseClient();
    const leftEarElectrode = channelNames.indexOf('TP9'); // Channel 0 (I think)
    const leftForeheadElectrode = channelNames.indexOf('AF7'); // Channel 1
    const rightForeheadElectrode = channelNames.indexOf('AF8'); // Channel 2
    const rightEarElectrode = channelNames.indexOf('TP10'); // Channel 3
    this.client = Client;

    try {

      await Client.connect();
      await Client.start();

      this.setState({
        isStarted: true,
        done: false,
        eegBtConnection: true
      });

      let rawEegStreams = Client.eegReadings;

      // Split stream into four observable streams: e0, e1, e2, and e3
      let [e0, eMore] = rawEegStreams.pipe(partition(r => r.electrode === 0)); // Assign electrode=0 case to e0, everything else to eMore
      let [e1, eEvenMore] = eMore.pipe(partition(r => r.electrode === 1)); // Assign electrode=1 case to e1, everything else to eEvenMore
      let [e2, e3] = eEvenMore.pipe(partition(r => r.electrode === 2)); // Assign electrode=2 case to e2, everything else (just e=3 in this case) to e3

      // Log stream data for each
      // this.logStreamData(e0, 0); // Call function logStreamData, passing in the RxJs observable (e0, here) and '0' - the electrode number
      this.logStreamData(e1, 1);
      this.logStreamData(e2, 2);
      // this.logStreamData(e3, 3);

      return true;
    } catch (e) {
      console.log('Error (connecting to EEG) is ', e);
      return false;
    }
  };


  // Function to take a stream of EEG data, epoch the data, take the fast fourier transform,
  // split data into power level by band (alpha, beta, delta, gamma, theta), and process it
  // to create focus (0-100), calm (0-100), and anxiety (100-0) scores
  logStreamData = (a, eNumber) => {
    a.pipe(
      epoch({
        //epoch is required, otherwise FFT crashes
        duration: 256, // Duration is #ms of data grouped into one epoch. Default 256
        interval: 20, // Interval is #ms between emmitted epochs. Default is 100, but that was very slow.
        samplingRate: 256, // Samping rate should match hardware sampling rate, in hz (Muse = 256hz)
        dataProp: 'samples' // Important. Muse labels their data 'samples', rather than the defualt 'data'.
      }),

      fft({ bins: 256, dataProp: 'samples' }),
      powerByBand()
    ).subscribe(eegPower => {
      // Initialize variables
      let inputs = [
        eegPower.alpha,
        eegPower.beta,
        eegPower.delta,
        eegPower.gamma,
        eegPower.theta
      ]; // Put brainstate inputs into an array to reduce typing. // Now we can iterate over this array to get things like StdDev, Variance, etc.
      let processed = []; // Processed var is an array of values. Processed.length should === headerLabels.length
      let averagedArrays = [];
      // Take average of each brainstate and push each to the 'processed' array
      for (let i = 0; i < inputs.length; i++) {
        averagedArrays.push(this.arrayAverage(inputs[i]));
        // processed.push(this.arrayAverage(inputs[i]))
      }

      // Find calm value based on Garrett's formula: calcCalm
      let calm = this.calcCalm(averagedArrays[0], averagedArrays[1], eNumber); // Call calcCalm function passing in ave Alpha (averagedArrays[0]), ave Beta, and the electrode number
      // processed.push(calm);

      // Find focus value based on Garrett's formula: calcFocus
      let focus = this.calcFocus(averagedArrays[1], averagedArrays[2], eNumber); // Call calcFocus function passing in ave Beta (averagedArrays[1]) and ave Delta, and the electrode number
      // processed.push(focus);

      // Find anxiety value based on Garrett's formula: calcAnxiety
      let anxiety = this.calcAnxiety(
        averagedArrays[2],
        averagedArrays[4],
        eNumber
      ); // Call calcFocus function passing in ave delta (averagedArrays[2]) and ave Theta, and the electrode number
      // processed.push(anxiety);

      // Finally update the state for electrodeOutputs
      this.setState({
        // electrodeOutputs: combinedOutputs,
        dataCount: this.state.dataCount + 1
      });
    });
  }; // End of logStreamData


  // Function to disconnect EEG Reader/Muse and construct a table with the raw data
  disconnectEegReader = () => {
    this.setState({ isStarted: false, done: true, eegBtConnection: false });
    this.client && this.client.disconnect();
  };


// ### FEATURE EXTRACTION FUNCTIONS ###

  // Calculate function for calm. Note: this function is designed for Electrode2
  calcCalm = (a, b, e) => {
    let aWeight = 0.8;
    let calmScore =
      aWeight * (81.7 - 52 * Math.log(a)) +
      (1 - aWeight) * (56.9 - 97 * Math.log(b));
    if (calmScore > 100) {
      calmScore = 100;
    }
    if (calmScore < 0) {
      calmScore = 0;
    }
    if (e === 2) {
      this.outputCalm(calmScore, e);
    }
    return calmScore;
  };


  // Calculate function for focus. Note: this function is designed for Electrode1
  calcFocus = (b, d, e) => {
    let dWeight = 0.75;
    let focus =
      dWeight * (109 - 7.15 * d - 1.82 * d ** 2) +
      (1 - dWeight) * (100 + 2.56 * b - 11.9 * b ** 2);
    if (focus > 100) {
      focus = 100;
    } // Cap focus at 100
    if (focus < 0) {
      focus = 0;
    } // Floor focus at 0
    if (e === 2) {
      this.outputFocus(focus, e);
    }
    return focus;
  };


  // Calculate function for anxiety. Note: this function is designed for Electrode1
  calcAnxiety = (d, t, e) => {
    // take args: delta, theta, and electrode number
    let dWeight = 0.6; // Weight detla heavier in our calculation
    let anxiety =
      dWeight * (-166 + 156 * Math.log(d)) +
      (1 - dWeight) * (10.9 - 31.1 * t + 10.3 * t ** 2); // Algorithm for anxiety (needs work) -Garrett
    if (anxiety > 100) {
      anxiety = 100;
    } // Cap focus at 100
    if (anxiety < 0) {
      anxiety = 0;
    } // Floor focus at 0
    if (e === 2) {
      this.outputAnxiety(anxiety, e);
    }
    return anxiety;
  };


  // Create an array of up to 5 values and output the average score.
  // This eliminates smooths the output (otherwise calm would vary wildly) and improves the experience
  outputCalm = (c, e) => {
    let array = [...this.state.calmScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(c);
    let ave = this.arrayAverage(array);

    this.setState({
      calmScores: array,
      calmAve: ave
    });
  };


  // Create an array of up to 5 values and output the average score.
  // This eliminates smooths the output (otherwise focus would vary wildly) and improves the experience
  outputFocus = (f, e) => {
    let array = [...this.state.focusScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(f);
    let ave = this.arrayAverage(array);
    ave = parseFloat(ave);

    let aveFocus = this.arrayAverage(array);
    this.setState({
      focusScores: array,
      focusAve: ave
    });
  };


  // Create an array of up to 5 values and output the average score.
  // This eliminates smooths the output (otherwise anxiety would vary wildly) and improves the experience
  outputAnxiety = (a, e) => {
    let array = [...this.state.anxietyScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(a);
    let ave = this.arrayAverage(array);

    this.setState({
      anxietyScores: array,
      anxietyAve: ave
    });
  };



// ### GAME FUNCTIONS ###

  startGame = () => {
    if(this.state.eegBtConnection){
        this.setState({playerScore: 0}); // reset player score
        this.startTimer(); // start the countdown timer
        this.startScoring(); // start the scorer
    } else {alert ("Please connect the Muse EEG first")}
  }

  stopGame = () => {
    this.stopTimer();
    this.checkAndSetMaxPlayerScore();
  }


  startTimer = () => {
    var initialCountdownTime = this.setTimerLength(this.state.initialCountdownTime); // this.state.initialCountdownTime is not set anywhere yet. Use it to take user input to set a variable game length
    var intervalId = setInterval(this.timer, 1000);
    // store intervalId in the state so it can be accessed later:
    this.setState({
      intervalId: intervalId, 
      timerFinished: false,
      countdownStarted: true
    });
  }


  setTimerLength = (time = 10) => {
    this.setState({
      currentCountdownCount: time
    })
  }


  timer = () => {
    if (this.state.currentCountdownCount > 0){
        this.setState({ currentCountdownCount: this.state.currentCountdownCount -1 });
    } else {
      this.setState({timerFinished: true})
      this.stopGame();
    }
  }


  stopTimer = () => {
    clearInterval(this.state.intervalId);
    clearInterval(this.interval);
    this.setState({
      countdownStarted: false,
    })
  }



  // Function to start user scoring
  startScoring = () => {
    const intervals = 60; // number of ms between updates to score
    this.interval = setInterval(() => this.updateScore(intervals), intervals);

  };


  // Update Score
  updateScore = dT => {
    const score =
      this.state.playerScore +
      Math.round(0.0138 * dT * this.state.calmAve);
    this.setState({ playerScore: score });
  };


  checkAndSetMaxPlayerScore = () => {
    if (this.state.playerScore > this.state.maxScore || !this.state.maxScore){
      let newHighScore = this.state.playerScore;
      this.setState({maxScore: newHighScore});
    }
  }





// ### HELPER FUNCTIONS ###

  // Function to find the mean average of data in an array...
  // and retun with precision up to 4 decimal places
  arrayAverage = a => {
    let sum = 0;
    for (let i = 0; i < a.length; i++) {
      sum += a[i];
    }
    let ave = sum / a.length;
    ave = this.truncate(ave, 3);
    return ave;
  };


  // Truncate long decimal values to l number of places
  truncate = (i, l) => {
    return i.toFixed(l);
  };


  // Function to transpose a matrix. Used to output more-readble CSV files for data
  transpose = m => m[0].map((x, i) => m.map(x => x[i]));



// ### MAIN FUNCTIONS (Render, ComponentDidMount, etc) ###
  componentWillUnmount() {
    this.stopTimer();
  }

  render() {
    return (
      <div className="App">
        <div className="controls">
          <button
            onClick={
              this.state.hrBtConnection
                ? this.disconnectHrBluetooth
                : this.connectHRBluetooth
            }
          >
            {this.state.hrBtConnection
              ? 'Disconnect HR monitor'
              : 'Connect HR monitor'}
          </button>
          <button
            onClick={
              this.state.eegBtConnection
                ? this.disconnectEegReader
                : this.connectEEGBluetooth
            }
          >
            {this.state.eegBtConnection
              ? 'Disconnect EEG monitor'
              : 'Connect EEG monitor'}
          </button>
          <button
            onClick={
              this.state.countdownStarted
                ? this.stopGame
                : this.startGame
            }
          >
            {this.state.countdownStarted
              ? 'Quit (not used yet)'
              : 'Start timer'}
          </button>
          <p>Current heart rate is: {this.state.hrBtConnection ? this.state.hr : ""}</p>
          <p>Current calm state is: {this.state.calmAve}</p>
          <p>Current focus state is: {this.state.focusAve}</p>
          <h1>Time left: {this.state.currentCountdownCount || "push start"}</h1>
          <h1>Your score: {this.state.playerScore || "waiting to start"}</h1>

          {this.state.maxScore &&
            <h3>Your best score is {this.state.maxScore}</h3>
          }




        </div>
      </div>
    );
  }
}

export default BrainGameText;
