

// Function to send Unity variables.

// Requires passing an object which contains ALL of:
	// 	unityInstance			// if using "UnityContent" method, pass 'this.unityContent in here'
	//	gameObject <string>		// name of the game object in Unity that we're passing data to
	// 	sentToMethod <string> 	// this is a string referenceing a public method (void) attached to the game object. It's the bridge between JS and WebGL
	//	itemToSend <varies> 	// the item we're passing to the above method in Unity/WebGl. Nearly always needs to be explicitly pre-converted before passing (EG. ParseFloat(), ToString() etc.)


export const sendToUnity = (
	passthroughs = {
		unityInstance: null,  // if using "UnityContent" method, pass 'this.unityContent in here'
		gameObject: "Player-mouse", 
		sentToMethod: "UpdateCalm", 
		itemToSend: 0.0
	}) => { 
    if (passthroughs.unityInstance){
	    let sentToMethodString = passthroughs.sentToMethod.toString(); // Check to make sure the method name is given as a string
	    
	    passthroughs.unityInstance.send( // Send Unity a message...
	      passthroughs.gameObject, // to this game object...
	      sentToMethodString, // to this public method...
	      passthroughs.itemToSend // of this message
	    );
	}
  }