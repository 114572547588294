import React, { PureComponent } from "react";
import { Route } from "react-router-dom";
import Unity, { UnityContent } from "react-unity-webgl";



// let basePath = "/Game/Build/";
// let dataUrl = "portalish.data.unityweb";
// let frameworkUrl = "portalish.wasm.framework.unityweb";
// let codeUrl = "portalish.wasm.code.unityweb";
   
// const unityContext = new UnityContext({
//   loaderUrl: basePath + "UnityLoader.js",
//   dataUrl: basePath + dataUrl,
//   frameworkUrl: basePath + frameworkUrl,
//   codeUrl: basePath + codeUrl   
// });



class Game extends PureComponent {


  constructor(props) {
    super(props);
 
    this.unityContent = new UnityContent(
      "/Game/Build/portalish.json",
      "/Game/Build/UnityLoader.js"
    );
  }
 
  render() {
    return( 

        <Unity unityContent={this.unityContent} />

      );
  }
}

export default Game;