import React, { PureComponent } from "react";


import Button from "../components/button";

import { LineChart } from "react-easy-chart";

import { filter, partition } from "rxjs/operators";
import { MuseClient, channelNames } from "muse-js";
// import * as NoGUI from "../modules/Muse/NoGUI";
import { CSVLink, CSVDownload } from "react-csv";
import {
  epoch,
  fft,
  bufferFFT,
  pipe,
  createEEG,
  powerByBand, // Returns an array of power for each frequeny band
  averagePower, // Returns average power of each channel
  standardDeviation,
  pickChannels,
  removeChannels
} from "@neurosity/pipes";

class Portalish extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isStarted: false,
      done: false,
      // Initalize and create array of arrays for alpha, beta, delta, gamma, and theta of each electrode
      flatData: [[]],
      dataCount: [],
      headerLabels: [
        "Index",
        "Alpha",
        "Beta",
        "Delta",
        "Gamma",
        "Theta",
        "A StdDev",
        "B StdDev",
        "D  StdDev",
        "G StdDev",
        "T  StdDev",
        "A Var",
        "B Var",
        "D Var",
        "G Var",
        "T Var",
        "A Relative",
        "B Relative",
        "D Relative",
        "G Relative",
        "T Relative",
        "A/B Ratio",
        "T/A Ratio",
        "Calm",
        "Focus",
        "Anxiety"
      ],
      numElectrodes: 4,
      electrodeOutputs: [[[]]],
      bigEmptyArray: [[[]]],
      userText: "tag",
      calmScores: [],
      calmAve: 0,
      focusScores: [],
      focusAve: 0,
      anxietyScores: [],
      anxietyAve: 0
    };


  }

  // Function to connect the Muse EEG reader; split EEGstreams, by electrode, into 4 observable streams, and call logStreamData for each stream
  connectEegReader = async () => {
    const Client = new MuseClient();
    const leftEarElectrode = channelNames.indexOf("TP9"); // Channel 0 (I think)
    const leftForeheadElectrode = channelNames.indexOf("AF7"); // Channel 1
    const rightForeheadElectrode = channelNames.indexOf("AF8"); // Channel 2
    const rightEarElectrode = channelNames.indexOf("TP10"); // Channel 3
    this.client = Client;

    // Generate empty initialized array of arrays of arrays so that React stops bitching.
    let emptyElectrodeOutputs = this.createBigEmptyArray();

    try {
      await Client.connect();
      await Client.start();

      this.setState({
        isStarted: true,
        done: false,
        electrodeOutputs: emptyElectrodeOutputs
      });

      let rawEegStreams = Client.eegReadings;

      // Split stream into four observable streams: e0, e1, e2, and e3
      let [e0, eMore] = rawEegStreams.pipe(partition(r => r.electrode === 0)); // Assign electrode=0 case to e0, everything else to eMore
      let [e1, eEvenMore] = eMore.pipe(partition(r => r.electrode === 1)); // Assign electrode=1 case to e1, everything else to eEvenMore
      let [e2, e3] = eEvenMore.pipe(partition(r => r.electrode === 2)); // Assign electrode=2 case to e2, everything else (just e=3 in this case) to e3

      // Log stream data for each
      this.logStreamData(e0, 0); // Call function logStreamData, passing in the RxJs observable (e0, here) and '0' - the electrode number
      this.logStreamData(e1, 1);
      this.logStreamData(e2, 2);
      this.logStreamData(e3, 3);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  // Function to take a stream of EEG data, epoch the data, take the fast fourier transform,
  // split data into power level by band (alpha, beta, delta, gamma, theta), and
  // log the data into one master array of arrays of arrays: electrodeOutputs
  logStreamData = (a, eNumber) => {
    a.pipe(
      epoch({
        //epoch is required, otherwise FFT crashes
        duration: 256, // Duration is #ms of data grouped into one epoch. Default 256
        interval: 20, // Interval is #ms between emmitted epochs. Default is 100, but that was very slow.
        samplingRate: 256, // Samping rate should match hardware sampling rate, in hz (Muse = 256hz)
        dataProp: "samples" // Important. Muse labels their data 'samples', rather than the defualt 'data'.
      }),

      fft({ bins: 256, dataProp: "samples" }),
      powerByBand()
    ).subscribe(eegPower => {
      // Initialize variables
      let newElectrodeOutputs = this.createBigEmptyArray(); // Create an empty array of arrays of arrays
      let prevOutputs = this.state.electrodeOutputs; // Grab previously recorded data
      let inputs = [
        eegPower.alpha,
        eegPower.beta,
        eegPower.delta,
        eegPower.gamma,
        eegPower.theta
      ]; // Put brainstate inputs into an array to reduce typing. // Now we can iterate over this array to get things like StdDev, Variance, etc.
      let headerLabels = this.state.headerLabels; // Create variables to make up our table
      let processed = []; // Processed var is an array of values. Processed.length should === headerLabels.length
      let averagedArrays = [];

      // Add an index number for each line of data
      const numberOfRecordingsSoFar = prevOutputs[eNumber][0].length;
      let index = numberOfRecordingsSoFar + 1;
      processed.push(index);

      // Take average of each brainstate and push each to the 'processed' array
      for (let i = 0; i < inputs.length; i++) {
        averagedArrays.push(this.arrayAverage(inputs[i]));
        processed.push(this.arrayAverage(inputs[i]));
      }
      // const calmPerfectAlpha =

      // Take StdDev of each brainstate and push each to the 'processed' array
      for (let j = 0; j < inputs.length; j++) {
        processed.push(this.truncate(standardDeviation(inputs[j]), 3));
      }

      // Take variance of each brainstate and push each to the 'processed' array
      for (let i = 0; i < inputs.length; i++) {
        // Loop for each power band
        let theTop = 0;
        let arrAve = this.arrayAverage(inputs[i]); // Find the mean of the items within the powerband
        for (let j = 0; j < inputs[i].length; j++) {
          theTop += (inputs[i][j] - arrAve) ** 2;
        }
        let variance = theTop / (inputs[i].length - 1);
        processed.push(this.truncate(variance, 3));
        // (inputs[i][j]-this.arrayAverage(inputs[i]))^2) / (inputs[i].length-1);
      }

      // Take relative value of each powerband and push each to the 'processed' array
      let relPowers = 0;
      let theBottom = 0;
      for (let j = 0; j < averagedArrays.length; j++) {
        theBottom += 10 ** averagedArrays[j]; // Find the sum of all 10^averagedPowerBands
      }
      for (let i = 0; i < averagedArrays.length; i++) {
        // Loop for each power band
        let output = 10 ** averagedArrays[i] / theBottom;
        processed.push(this.truncate(output, 10));
      }

      // Find relevant ratios of Alpha/Beta and Theta/Alpha
      let alphaVSbeta =
        this.arrayAverage(eegPower.alpha) / this.arrayAverage(eegPower.beta);
      processed.push(this.truncate(alphaVSbeta, 3));
      let thetaVsAlpha =
        this.arrayAverage(eegPower.theta) / this.arrayAverage(eegPower.beta);
      processed.push(this.truncate(thetaVsAlpha, 3));

      // Find calm value based on Garrett's formula: calcCalm
      let calm = this.calcCalm(averagedArrays[0], averagedArrays[1], eNumber); // Call calcCalm function passing in ave Alpha (averagedArrays[0]), ave Beta, and the electrode number
      processed.push(calm);

      // Find focus value based on Garrett's formula: calcFocus
      let focus = this.calcFocus(averagedArrays[1], averagedArrays[2], eNumber); // Call calcFocus function passing in ave Beta (averagedArrays[1]) and ave Delta, and the electrode number
      processed.push(focus);

      // Find anxiety value based on Garrett's formula: calcFocus
      let anxiety = this.calcAnxiety(
        averagedArrays[2],
        averagedArrays[4],
        eNumber
      ); // Call calcFocus function passing in ave delta (averagedArrays[2]) and ave Theta, and the electrode number
      processed.push(anxiety);

      // NOTE: to add more processed data, the headerLabels state must be updated to match

      // Loop through 'processed' variable saving each element to an array called newElectrodeOutputs,
      // then stitch it together with previous outputs array to create one array to rule them all
      for (let i = 0; i < processed.length; i++) {
        newElectrodeOutputs[eNumber][i] = processed[i]; // Create an array, newElectrodeOutputs, and save each new averaged brainState to it
        prevOutputs[eNumber][i] = [
          ...prevOutputs[eNumber][i],
          newElectrodeOutputs[eNumber][i]
        ]; // Then stitch together new data with previous electrode outputs
      }

      let combinedOutputs = prevOutputs; // Change name to make it readable now that prevOutputs includes newElectrodeOutputs

      // Finally update the state for electrodeOutputs
      this.setState({
        electrodeOutputs: combinedOutputs,
        dataCount: this.state.dataCount + 1
      });

      // Console logging for debug
      /*
        console.log("-----------------------------------");
        console.log("Electrode number is: " + eNumber);
        console.log("The alpha average is " + alphaAve);
        console.log("The beta average is " + betaAve);
        console.log("The delta average is " + deltaAve);
        console.log("The gamma average is " + gammaAve);
        console.log("The theta average is " + thetaAve);

        console.log("");

        console.log("Electrode outputs are:");
        console.log(this.state.electrodeOutputs);

        console.log("");
        */

      // console.log("Electrode output power (should update one electrode):");
      // console.log(eegPower);
      // console.log("");
    });
  }; // End of logStreamData

  // Function to disconnect EEG Reader/Muse and construct a table with the raw data
  disconnectEegReader = () => {
    this.setState({ isStarted: false, done: true });
    this.client && this.client.disconnect();

    let data = this.state.electrodeOutputs; // Grab all recorded data found in the one main array

    let flatArray = this.packDataForCSV(data); // flatten out the one main array, 'from array of arrays of arrays' to 'array of arrays'
    /*
    flatArray = this.transpose(flatArray); // sortof works, but limits data length to length of E0
    */

    // Trying to get our CSV download to download data of larger sizes
    /*
    let labeledArray = this.createBigEmptyArray();
    let headerLabels = this.state.headerLabels;
    for (let i = 0; i < data.length; i++ ) {
      let children = []
      for (let j = 0; j < headerLabels.length; j++) {
        labeledArray[i][headerLabels[j]] = data[i][j] // $$$ LEFT OFF HERE $$$. This successfully created key:value pairs, though CSVLink didn't respond
      }
    };
    */

    this.setState({
      flatData: flatArray
      // flatData: transposedArray,
      // flatData: labeledArray,
    });
  };

  // ### FEATURE EXTRACTION FUNCTIONS ###

  // Calculate function for calm. Note: this function is designed for Electrode2
  calcCalm = (a, b, e) => {
    let aWeight = 0.8;
    let calmScore =
      aWeight * (81.7 - 52 * Math.log(a)) +
      (1 - aWeight) * (56.9 - 97 * Math.log(b));
    if (calmScore > 100) {
      calmScore = 100;
    }
    if (calmScore < 0) {
      calmScore = 0;
    }
    if (e === 2) {
      this.outputCalm(calmScore, e);
    }
    return calmScore;
  };

  // Calculate function for focus. Note: this function is designed for Electrode1
  calcFocus = (b, d, e) => {
    let dWeight = 0.75;
    let focus =
      dWeight * (109 - 7.15 * d - 1.82 * d ** 2) +
      (1 - dWeight) * (100 + 2.56 * b - 11.9 * b ** 2);
    if (focus > 100) {
      focus = 100;
    } // Cap focus at 100
    if (focus < 0) {
      focus = 0;
    } // Floor focus at 0
    if (e === 2) {
      this.outputFocus(focus, e);
    }
    return focus;
  };

  // Calculate function for focus. Note: this function is designed for Electrode1
  calcAnxiety = (d, t, e) => {
    // take args: delta, theta, and electrode number
    let dWeight = 0.6; // Weight detla heavier in our calculation
    let anxiety =
      dWeight * (-166 + 156 * Math.log(d)) +
      (1 - dWeight) * (10.9 - 31.1 * t + 10.3 * t ** 2);
    if (anxiety > 100) {
      anxiety = 100;
    } // Cap focus at 100
    if (anxiety < 0) {
      anxiety = 0;
    } // Floor focus at 0
    if (e === 2) {
      this.outputAnxiety(anxiety, e);
    }
    return anxiety;
  };

  // Create an array of up to 5 values and output the average score.
  // This eliminates 'death spirals' for users and smooths the experience
  outputCalm = (c, e) => {
    let array = [...this.state.calmScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(c);
    let aveCalm = this.arrayAverage(array);
    this.setState({
      calmScores: array,
      calmAve: aveCalm
    });
  };

  // Create an array of up to 5 values and output the average score.
  // This eliminates 'death spirals' for users and smooths the experience
  outputFocus = (f, e) => {
    let array = [...this.state.focusScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(f);
    let aveFocus = this.arrayAverage(array);
    this.setState({
      focusScores: array,
      focusAve: aveFocus
    });
  };

  // Create an array of up to 5 values and output the average score.
  // This eliminates 'death spirals' for users and smooths the experience
  outputAnxiety = (a, e) => {
    let array = [...this.state.anxietyScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(a);
    let ave = this.arrayAverage(array);
    this.setState({
      anxietyScores: array,
      anxietyAve: ave
    });
  };

  // ### HELPER FUNCTIONS ###

  // Generate empty initialized array of arrays of arrays so that React stops bitching.
  createBigEmptyArray = () => {
    let aOfaOfarrays = [];
    for (let i = 0; i < this.state.numElectrodes; i++) {
      // Create children
      let children = [];
      for (let j in this.state.headerLabels) {
        children.push([]);
      }
      aOfaOfarrays.push(children);
    }
    return aOfaOfarrays;
  };

  // Function to find the mean average of data in an array...
  // and retun with precision up to 4 decimal places
  arrayAverage = a => {
    let sum = 0;
    for (let i = 0; i < a.length; i++) {
      sum += a[i];
    }
    let ave = sum / a.length;
    ave = this.truncate(ave, 3);
    return ave;
  };

  // Truncate long decimal values to l number of places
  truncate = (i, l) => {
    return i.toFixed(l);
  };

  // Function to put data into a format that allows download of all electrodes to a CSV
  packDataForCSV = data => {
    var merged = [].concat.apply([], data); // Merge arrays, leaving an array of arrays
    return merged;
  };

  // Function to add the strings, such as "BREAK", to each array when user asks to create a breakpont.
  // Used to mark points, such as when a user puts the headset on, or when the user starts a new
  // action (EG. focusing).
  causeBreak = string => {
    let currentData = this.state.electrodeOutputs;
    let newItems = this.createBigEmptyArray();
    let prevOutputs = this.state.electrodeOutputs;

    for (let j = 0; j < currentData.length; j++) {
      for (let i = 0; i < currentData[j].length; i++) {
        newItems[j][i] = string || "Break"; // Create an array, newItems, and append a new item called "Break"
        prevOutputs[j][i] = [...prevOutputs[j][i], newItems[j][i]]; // Then stitch together new data with previous electrode outputs
      }
    }

    let newOutputWithBreak = prevOutputs;

    this.setState({
      electrodeOutputs: newOutputWithBreak
    });
  };

  // Function to create each table
  createTable = (brainStateArray, electrodeNum) => {
    let table = [];

    // Outer loop to create parent
    for (let i = 0; i < brainStateArray[0].length; i++) {
      let children = [];
      //Inner loop to create children
      for (let j = 0; j < brainStateArray.length; j++) {
        children.push(<td>{brainStateArray[j][i]}</td>);
      }
      //Create the parent and add the children
      table.push(<tr>{children}</tr>);
    }
    return table;
  };

  // Function to create header labels for the big, combined tables.
  mergedHeaderLabels = () => {
    let output = [];
    for (let i = 0; i < this.state.numElectrodes; i++) {
      // Outer loop, by electrode
      for (let j in this.state.headerLabels) {
        // Inner loop, by header
        output.push(
          String("E" + i + " " + this.state.headerLabels[j]) // Creates an array of strings of format 'E# {headerName}'
        );
      }
    }
    /* Format will look something like (note that additional calcs, such as StdDev, will be included):
    [
      "E0 Alpha", "E0 Beta", "E0 Delta", "E0 Gamma", "E0 Theta",
      "E1 Alpha", "E1 Beta", "E1 Delta", "E1 Gamma", "E1 Theta",
      "E2 Alpha", "E2 Beta", "E2 Delta", "E2 Gamma", "E2 Theta",
      "E3 Alpha", "E3 Beta", "E3 Delta", "E3 Gamma", "E3 Theta"
    ]
    */
    return output;
  };

  // Function to set headers on each table so that I don't have to change headers in multiple
  // places every time I'd like to add an additional calculation
  createHeaders = () => {
    let headers = this.state.headerLabels;
    let tableHeaders = [];

    for (let i = 0; i < headers.length; i++) {
      // For each item found in the headerLabels state...
      tableHeaders.push(<th>{headers[i]}</th>); // eush each header name into the tableHeaders array
    }
    return tableHeaders;
  };

  // Function to transpose a matrix. Used to output more-readble CSV files for data
  transpose = m => m[0].map((x, i) => m.map(x => x[i]));
  // transpose = matrix => matrix.reduce(($, row) => row.map((_, i) => [...($[i] || []), row[i]]), [])

  logText = evt => {
    this.setState({
      userText: String(evt.nativeEvent.target.value)
    });
  };

  render() {
    const data = [{ x: 0, y: 0 },{ x: 1, y: 2 },{ x: 2, y: 4 },{ x: 3, y: 6 },{ x: 4, y: 8 },];

    let graphDataTemplate = {
      id : "" ,// string
      name: "", // string
      color: "cardinal", // string
      points: [ { x:0, y:0 } ] // array of objects
    }

    
    return (
      <>
        {!this.state.isStarted && (
          <div>
            <Button text="Tap to Connect" onClick={this.connectEegReader} />
          </div>
        )}

        {this.state.isStarted && (
          <div>
            <table>
              <tbody>
                <tr>
                  <td>Focus score: {this.state.focusAve}</td>
                  <td>Calm score: {this.state.calmAve}</td>
                  <td>Anxiety score: {this.state.anxietyAve}</td>
                </tr>
                <tr>
                  <td>
                    {this.state.focusScores.map((item, index) => {
                      return <p>{item}</p>;
                    })}
                  </td>
                  <td>
                    {this.state.calmScores.map((item, index) => {
                      return <p>{item}</p>;
                    })}
                  </td>
                  <td>
                    {this.state.anxietyScores.map((item, index) => {
                      return <p>{item}</p>;
                    })}
                  </td>
                </tr>
              </tbody>
            </table>

            <Button
              text="Add break reference"
              onClick={() => this.causeBreak("Break")}
            />
            <div>
              <p>Tag name</p>
              <input type="text" onKeyUp={this.logText} />
            </div>
            <Button
              text={"Add start " + this.state.userText}
              onClick={() =>
                this.causeBreak("Start " + this.state.userText + " reference")
              }
            />
            <Button
              text={"Add stop " + this.state.userText}
              onClick={() =>
                this.causeBreak("Stop " + this.state.userText + " reference")
              }
            />
          </div>
        )}

        {this.state.isStarted && (
          <Button text="Done" onClick={this.disconnectEegReader} />
        )}
        <div>
          {this.state.done && (
            <div>
              <CSVLink
                filename="All electrodes"
                data={this.state.flatData}
                headers={this.mergedHeaderLabels()}
                style={mergedDownloadStyle}
              >
                Download all data to CSV
              </CSVLink>
              <p style={buggyDlStyle}>
                <em>
                  Note: Data must be transposed, currently the headers should be
                  vertical to be accurate
                </em>
              </p>
            </div>
          )}

          {this.state.electrodeOutputs.map((electrode, index) => {
            // Map each parent electrode
            return (

              <div style={tableContainerStyle}>
                <CSVLink
                  filename={"Electrode " + index + " data"}
                  data={this.transpose(electrode)}
                  headers={this.state.headerLabels}
                  style={downloadStyle}
                >
                  Download data for electrode {index}
                </CSVLink>
                <table style={tableStyle}>
                  <caption>Output of electrode {index}</caption>
                  <thead>
                    <tr>{this.createHeaders()}</tr>
                  </thead>

                  <tbody>
                    {this.createTable(electrode, index)}{" "}
                    {/*Function to create table. Input is Array(5), containing each brainstate*/}
                  </tbody>
                  <br />
                </table>
              </div>
            );
          })}
        </div>


        <LineChart
          axes
          grid
          verticalGrid
          interpolate={"cardinal"}
          lineColors={["pink"]}
          width={1000}
          height={150}

          data={[data]}
        />
      </>
    );
  }
}

export default Portalish;

const tableContainerStyle = {
  width: "100vw",
  float: "left"
};

const tableStyle = {
  marginTop: "15px"
};

const mergedDownloadStyle = {
  color: "#000",
  backgroundColor: "#Ff0",
  padding: "10px",
  clear: "both",
  display: "block",
  width: "20vw",
  textAlign: "center",
  margin: "0 auto 20px"
};

const downloadStyle = {
  color: "#000",
  backgroundColor: "#fff",
  padding: "7px"
};

const buggyDlStyle = {
  textAlign: "center",
  marginBottom: "60px"
};

const dontShow = { display: "none" };
