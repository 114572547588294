import React from "react";
import styled from "styled-components";
import colors from "../config/defaultColors";

const Button = props => {
	let active = true;
	if (props.active !== undefined && props.active === false) active = false;
	return (
		<Container
			onClick={active ? props.onClick : null}
			fullWidth={props.fullWidth}
			noborder={props.noborder}
			fixedBottom={props.fixedBottom}
			active={active}
			className={props.className}
			hide={props.hide}
			marginTop={props.marginTop}
		>
			<Text
				fullWidth={props.fullWidth}
				noborder={props.noborder}
				small={props.small}
				fixedBottom={props.fixedBottom}
				boldlink={props.boldlink}
			>
				{props.text}
			</Text>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin-top: ${props => (props.marginTop ? props.marginTop : 0)}; 
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2.75vh;
	overflow: hidden;
	opacity: ${props => (props.active ? 1 : 0.16)};
	background: ${props => (props.noborder ? "none" : colors.ltPurple)};
	border: ${props =>
		props.noborder ? "none" : "0.1rem solid " + colors.dkBlue + ";"};
	// background: ${props => (props.fixedBottom ? colors.dkPurple + "cc" : "none")};
	height: ${props => (props.noborder ? "5.8vh;" : "7.8vh;")};
	min-height: 3.5rem;
	transition: opacity 0.75s, height 0.75s;
	z-index: 2;
	max-width: 70vw;


	${props =>
		props.fixedBottom
			? "position: absolute; bottom:0; height:9vh; margin:0 auto;background:" + colors.dkPurple + "cc ;max-width: 100vw;"
			: ""};
	&.hidden{
		height: 0; // Allows a better show/hide transition experience than display:none
		opacity: 0;
	}
	&:after {
		position: absolute;
		width: auto;
		margin-top: -0.2vh;
		right: 2rem;
		content: ${props => (props.noborder ? "" : '">"')};
		font-size: 3.2vh;
		white-space: pre;
	}

	${props => props.hide ? "display: none" : ""} // Don't on carousel unless explicitly called
`;
const Text = styled.span`
	width: ${props => (props.fullWidth ? "100vw" : "70vw")};
	${props =>
		props.small ? "font-size: 3.055vw; font-size: 2.3vh" : "font-size: calc(1rem + 2.5vmin);"}; //2.8rem was old large size // font-size in VH is *hopefully* a responsive fix.
	${props => (props.fixedBottom ? "font-size: 4.817vw;" : "")};
	${props =>
		props.boldlink ? "font-weight: 700; text-decoration: underline; color: #fff" : ""};
`;

export default Button;
