import React, { PureComponent } from "react";
import { Route } from "react-router-dom";
import {connectEEGBluetooth, disconnectEEGBluetooth} from '../components/eeg-reader-and-scoring'

 
class TestConnections extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {};
  }
 
  render() {
    return( 
      <>
        <button onClick={() => connectEEGBluetooth(this)}>Connect EEG</button>
      </>
      );
  }
}

export default TestConnections;