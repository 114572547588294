import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import styled from "styled-components";


import BrainGrapher from "./views/brain-graphing";
import BrainGameText from "./views/brainGameText";
import BrainGame3d from "./views/brainGame";
import MentalRunnerGame from "./views/mentalRunnerGame";
import TestConnections from "./views/testConnections";





const App = props => {

  return(
    <> 
      <BrowserRouter>
        <div id="main-container">
          <Header>
            <Link to="/">Home</Link>
          </Header>

          {/*<Suspense fallback={<>loading...</>}>*/}

            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route exact path="/grapher" component={BrainGrapher} />
              <Route exact path="/text-game" component={BrainGameText} />
              <Route exact path="/3d-game" component={BrainGame3d} />
              <Route exact path="/m" component={MentalRunnerGame} />
              <Route path="/test-connections" component={TestConnections} />


            </Switch>
          {/*</Suspense>*/}
        </div>
      </BrowserRouter>
    </>

  )
}

export default App;


const Homepage = () => {
  return(
    <>
      <h1>Web neurofeedback demo</h1>
      <p><Link to="/grapher">Grapher</Link></p>
      <p><Link to="/text-game">Brain Game - text only</Link></p>
      <p><Link to="/3d-game">Brain Game (3D via WebGL)</Link></p>
      <p><Link to="/m">Mental Runner game</Link></p>
      <p><Link to="/test-connections">Connection tester</Link></p>



    </>
  )
}

const Header = styled.div`
  padding: 10px;
  background-color: #efeaee;
`
