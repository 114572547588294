import React, { PureComponent } from 'react';
import { Route } from 'react-router-dom';

import { filter, partition } from 'rxjs/operators';
import { MuseClient, channelNames } from 'muse-js';

import {
  epoch,
  fft,
  bufferFFT,
  pipe,
  createEEG,
  powerByBand, // Returns an array of power for each frequeny band
  averagePower, // Returns average power of each channel
  standardDeviation,
  pickChannels,
  removeChannels
} from '@neurosity/pipes';




// NOTE FOR MYSELF: You just copy/pasted this here to build a Neurofeedback component.
  // You also were going to split out the HR monitor stuff into it's own component.


// EEG (MUSE) BLUETOOTH HANDLERS

const initStates = (parentThis) => {
  parentThis.setState({
      eegBtConnection: null,
      isStarted: false,
      done: false,
      // Initalize and create array of arrays for alpha, beta, delta, gamma, and theta of each electrode
      flatData: [[]],
      dataCount: [],
      numElectrodes: 4,
      userText: 'tag',
      calmScores: [],
      calmAve: null,
      focusScores: [],
      focusAve: null,
      anxietyScores: [],
      anxietyAve: 0
    });
}

      // Function to connect the Muse EEG reader; split EEGstreams, by electrode, into 4 observable streams, and call logStreamData for each stream
 export const  connectEEGBluetooth = async (parentThis) => {
    if(!parentThis.state.eegBtConnection){initStates(parentThis);} // Makes sure all states are ready


    const Client = new MuseClient();
    const leftEarElectrode = channelNames.indexOf('TP9'); // Channel 0 (I think)
    const leftForeheadElectrode = channelNames.indexOf('AF7'); // Channel 1
    const rightForeheadElectrode = channelNames.indexOf('AF8'); // Channel 2
    const rightEarElectrode = channelNames.indexOf('TP10'); // Channel 3
    parentThis.client = Client; // I'm not sure I can disconnect if I do it this way

    try {

      await Client.connect();
      await Client.start();

      parentThis.setState({
        isStarted: true,
        done: false,
        eegBtConnection: true
      });

      let rawEegStreams = Client.eegReadings;

      // Split stream into four observable streams: e0, e1, e2, and e3
      let [e0, eMore] = rawEegStreams.pipe(partition(r => r.electrode === 0)); // Assign electrode=0 case to e0, everything else to eMore
      let [e1, eEvenMore] = eMore.pipe(partition(r => r.electrode === 1)); // Assign electrode=1 case to e1, everything else to eEvenMore
      let [e2, e3] = eEvenMore.pipe(partition(r => r.electrode === 2)); // Assign electrode=2 case to e2, everything else (just e=3 in this case) to e3

      // Log stream data for each
      // this.logStreamData(e0, 0); // Call function logStreamData, passing in the RxJs observable (e0, here) and '0' - the electrode number
      logStreamData(e1, 1, parentThis);
      logStreamData(e2, 2, parentThis);
      // this.logStreamData(e3, 3);

      return true;
    } catch (e) {
      console.log('Error (connecting to EEG) is ', e);
      return false;
    }
  };


  // Function to take a stream of EEG data, epoch the data, take the fast fourier transform,
  // split data into power level by band (alpha, beta, delta, gamma, theta), and process it
  // to create focus (0-100), calm (0-100), and anxiety (100-0) scores
  const logStreamData = (a, eNumber, parentThis) => {
    a.pipe(
      epoch({
        //epoch is required, otherwise FFT crashes
        duration: 256, // Duration is #ms of data grouped into one epoch. Default 256
        interval: 20, // Interval is #ms between emmitted epochs. Default is 100, but that was very slow.
        samplingRate: 256, // Samping rate should match hardware sampling rate, in hz (Muse = 256hz)
        dataProp: 'samples' // Important. Muse labels their data 'samples', rather than the defualt 'data'.
      }),

      fft({ bins: 256, dataProp: 'samples' }),
      powerByBand()
    ).subscribe(eegPower => {
      // Initialize variables
      let inputs = [
        eegPower.alpha,
        eegPower.beta,
        eegPower.delta,
        eegPower.gamma,
        eegPower.theta
      ]; // Put brainstate inputs into an array to reduce typing. // Now we can iterate over this array to get things like StdDev, Variance, etc.
      let processed = []; // Processed var is an array of values. Processed.length should === headerLabels.length
      let averagedArrays = [];
      // Take average of each brainstate and push each to the 'processed' array
      for (let i = 0; i < inputs.length; i++) {
        averagedArrays.push(arrayAverage(inputs[i]));
        // processed.push(this.arrayAverage(inputs[i]))
      }

      // Find calm value based on Garrett's formula: calcCalm
      let calm = calcCalm(averagedArrays[0], averagedArrays[1], eNumber, parentThis); // Call calcCalm function passing in ave Alpha (averagedArrays[0]), ave Beta, and the electrode number
      // processed.push(calm);

      // Find focus value based on Garrett's formula: calcFocus
      let focus = calcFocus(averagedArrays[1], averagedArrays[2], eNumber, parentThis); // Call calcFocus function passing in ave Beta (averagedArrays[1]) and ave Delta, and the electrode number
      // processed.push(focus);

      // Find anxiety value based on Garrett's formula: calcAnxiety
      let anxiety = calcAnxiety(
        averagedArrays[2],
        averagedArrays[4],
        eNumber,
        parentThis
      ); // Call calcFocus function passing in ave delta (averagedArrays[2]) and ave Theta, and the electrode number
      // processed.push(anxiety);

      // Finally update the state for electrodeOutputs
      parentThis.setState({
        dataCount: parentThis.state.dataCount + 1
      });
    });
  }; // End of logStreamData


  // Function to disconnect EEG Reader/Muse and construct a table with the raw data
  export const disconnectEegReader = (parentThis) => {
    // this.setState({ isStarted: false, done: true, eegBtConnection: false });
    parentThis.client && parentThis.client.disconnect();
  };




// ### FEATURE EXTRACTION FUNCTIONS ###

// Calculate function for calm. Note: this function is designed for Electrode2
const calcCalm = (a, b, e, parentThis) => {
  let aWeight = 0.8;
  let calmScore =
    aWeight * (81.7 - 52 * Math.log(a)) +
    (1 - aWeight) * (56.9 - 97 * Math.log(b));
  if (calmScore > 100) {
    calmScore = 100;
  }
  if (calmScore < 0) {
    calmScore = 0;
  }
  if (e === 2) {
    outputCalm(calmScore, e, parentThis);
  }
  return calmScore;
};


// Calculate function for focus. Note: this function is designed for Electrode1
const calcFocus = (b, d, e, parentThis) => {
  let dWeight = 0.75;
  let focus =
    dWeight * (109 - 7.15 * d - 1.82 * d ** 2) +
    (1 - dWeight) * (100 + 2.56 * b - 11.9 * b ** 2);
  if (focus > 100) {
    focus = 100;
  } // Cap focus at 100
  if (focus < 0) {
    focus = 0;
  } // Floor focus at 0
  if (e === 2) {
    outputFocus(focus, e, parentThis);
  }
  return focus;
};


// Calculate function for anxiety. Note: this function is designed for Electrode1
const calcAnxiety = (d, t, e, parentThis) => {
  // take args: delta, theta, and electrode number
  let dWeight = 0.6; // Weight detla heavier in our calculation
  let anxiety =
    dWeight * (-166 + 156 * Math.log(d)) +
    (1 - dWeight) * (10.9 - 31.1 * t + 10.3 * t ** 2); // Algorithm for anxiety (needs work) -Garrett
  if (anxiety > 100) {
    anxiety = 100;
  } // Cap focus at 100
  if (anxiety < 0) {
    anxiety = 0;
  } // Floor focus at 0
  if (e === 2) {
    outputAnxiety(anxiety, e, parentThis);
  }
  return anxiety;
};


  // Create an array of up to 5 values and output the average score.
  // This eliminates smooths the output (otherwise calm would vary wildly) and improves the experience
  export const outputCalm = (c, e, parentThis) => {
    let array = [...parentThis.state.calmScores]; // problematic....
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(c);
    let ave = arrayAverage(array);

    if (parentThis){
        parentThis.setState({
          calmScores: array,
          calmAve: ave
        })
    }
  };


  // Create an array of up to 5 values and output the average score.
  // This eliminates smooths the output (otherwise focus would vary wildly) and improves the experience
  export const outputFocus = (f, e, parentThis) => {
    let array = [...parentThis.state.focusScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(f);
    let ave = arrayAverage(array);
    ave = parseFloat(ave);

    let aveFocus = arrayAverage(array);
    if (parentThis){
      parentThis.setState({
        focusScores: array,
        focusAve: ave
      })
    }
  };


  // Create an array of up to 5 values and output the average score.
  // This eliminates smooths the output (otherwise anxiety would vary wildly) and improves the experience
  export const outputAnxiety = (a, e, parentThis) => {
    let array = [...parentThis.state.anxietyScores];
    if (array.length >= 5) {
      // If array already contains 5 items
      array.splice(0, 1);
    }
    array.push(a);
    let ave = arrayAverage(array);

    if (parentThis){
      parentThis.setState({
        anxietyScores: array,
        anxietyAve: ave
      })
    }
  };


// Function to find the mean average of data in an array...
// and retun with precision up to 4 decimal places
const arrayAverage = a => {
  let sum = 0;
  for (let i = 0; i < a.length; i++) {
    sum += a[i];
  }
  let ave = sum / a.length;
  ave = truncate(ave, 3);
  return ave;
};


const  truncate = (i, l) => {
  return i.toFixed(l);
};


